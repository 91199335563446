import React from 'react'
import { Link } from 'gatsby'
import * as styles from './learn-more.module.scss'
import calenderIcon from '../../../static/icons/calender.svg'

interface Props {
    to: string
    text: string
    icon?: boolean
}

const LearnMore: React.FC<Props> = ({to, text, icon }) => {
    return(
        <Link className={styles.container} to={to}>
            <div>
                {
                    (icon === true) && (
                        <img src={calenderIcon} alt={text} />
                    )
                } 
                {text}
            </div>
        </Link>
    )
}

export default LearnMore