// extracted by mini-css-extract-plugin
export const transitionFromTop = "text-w-image-full-module--transition-from-top--3_BgY";
export const container = "text-w-image-full-module--container--20Ob6";
export const imageContainer = "text-w-image-full-module--image-container--1BrIX";
export const textContainer = "text-w-image-full-module--text-container--2-6I0";
export const imageContainerCover = "text-w-image-full-module--image-container-cover--240w7";
export const imageContainerContain = "text-w-image-full-module--image-container-contain--3KGFt";
export const link = "text-w-image-full-module--link--3oMcW";
export const alignRight = "text-w-image-full-module--align-right--3hdVa";
export const textContent = "text-w-image-full-module--text-content--1NvHA";
export const alignLeft = "text-w-image-full-module--align-left--2fI5d";