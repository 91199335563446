import React from 'react'
import * as styles from './hero-section.module.scss'
import LearnMore from '../minor-components/learn-more'

interface Props {

}

const HeroSection: React.FC<Props> = () => {
    return (
        <div>
            <div className={styles.heroContainer}>
                <div className={styles.contentContainer}>
                    <div>
                        <h1><span>addlevel</span> is now an integrated part of <span>Truesec</span></h1>
                        <h4>
                            As of the start of 2021, all employees will transition to be working under the Truesec brand.
                        </h4>
                    </div>
                </div>
            </div>
            <div className={styles.heroDescriptionContainer}>
                <div className={styles.contentDescriptionContainer}>
                    <div className={styles.descriptionItem}>
                        <p>
                        "Addlevel will be fully integrated with Truesec from now on. It provides a great and needed boost of expertise and capacity for the upcoming 2021. We look forward to work even closer together.”
                        </p>
                    </div>
                    <div className={styles.descriptionItem}>
                        <h4>Some words from <span>Truesec</span> CEO</h4>
                        <h4>- Anna Averud</h4>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroSection