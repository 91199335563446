import React from 'react'
import Layout from '../components/layout-components/layout'
import HeroSection from '../components/page-components/hero-section'
import TextWithImageFull from '../components/page-components/text-w-image-full'

interface Props {

}

const Index: React.FC<Props> = () => {
    const pageName = "Home"
    return (
        <Layout hero={<HeroSection />}>
            <TextWithImageFull 
                rightAlignImage={true}>
                <h2>Press release</h2>
                <p>
                As of the start of 2021, Addlevel will be a fully integrated part of the Truesec group. Personnel at Addlevel focusing on strategic cyber security will now work directly under the Truesec brand. All the developers at Addlevel currently focusing on the nodeProtect platform, will start a new department under Truesec Development in Stockholm and focus on the digital offerings provided by the whole Truesec group.
                </p>
            </TextWithImageFull>
            <TextWithImageFull>
                <h2>About Truesec</h2>
                <p>
                Truesec is a leading cyber security consulting company and offers a wide range of services including security health checks, security engineering, penetration testing, security operations and development services all provided by cyber security specialists
                </p>
            </TextWithImageFull>
        </Layout>
    )
}

export default Index