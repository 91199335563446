import React from 'react'
import * as styles from './text-w-image-full.module.scss'
import LearnMore from '../minor-components/learn-more'

interface Props {
    rightAlignImage?: boolean
    image?: string
    imageContain?: boolean
    optionalRoutePath?: string
    optionalRouteText?: string
}

const TextWithImageFull: React.FC<Props> = ({
    children, 
    rightAlignImage, 
    image, 
    imageContain, 
    optionalRoutePath, 
    optionalRouteText}) => {
    var imageStyle: any

    return(
        <div className={`${styles.container} ${(rightAlignImage ? styles.alignRight: styles.alignLeft)}`}>
            <div className={`${imageStyle} ${(imageContain ? styles.imageContainerContain : styles.imageContainerCover)}`}>
                <p></p>
            </div>
            <div className={styles.textContainer}>
                <div className={styles.textContent}>
                    {children}

                    {
                        (optionalRoutePath != null && optionalRouteText != null) && (
                            <div className={styles.link}>
                                <LearnMore to={optionalRoutePath} text={optionalRouteText} />
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default TextWithImageFull